.Contact {
    width: 400px;
    color: var(--color-light);
}

.Contact .email {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.Contact .email input {
    margin: 0 0 8px 0;
}

.Contact .email textarea {
    margin: 0 0 8px 0;
}

.Contact .ErrorMessage {
    margin: 0 0 8px 0;
}
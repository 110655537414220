.Button {
    border-radius: 4px;
    border: 2px solid var(--color-light);
    font-size: 1rem;
    padding: 4px 8px;
    cursor: pointer;
    user-select: none;
    color: var(--color-light);
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
}

.Button:hover {
    background-color: var(--color-light);
    color: var(--color-dark);
}

.Button:active {
    background-color: var(--color-light-dark);
    border: 2px solid var(--color-light-dark);
    color: var(--color-dark);
}
.App {
  text-align: center;
  background-color: var(--color-dark);
  min-height: 100vh;
  font-size: 1.2em;
  line-height: 1.5;
}

.App .body {
  padding: 24px 16px 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

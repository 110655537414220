.Experience {
    width: 750px;
}

.DateRange {
    margin: 8px 0 0 0;
    text-align: left;
    font-size: 1rem;
    font-style: italic;
}

.ExperienceItem {
    color: var(--color-light);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: start;
    margin: 16px 0 32px 0;
}
.ExperienceItem .left-column {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
}

.ExperienceItem .left-column .logo {
    height: 1.25rem;
    object-fit: contain;
    margin: 8px 0 0 0 ;
}

.ExperienceItem .left-column .date-range {
    font-size: 1rem;
}

.ExperienceItem .left-column .Button {
    margin: 8px 0 0 0;
    min-width: 96px;
}

.ExperienceItem .right-column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 500px;
    justify-content: start;
    align-items: start;
    text-align: left;
}

.ExperienceItem .right-column .description {
    width: 100%;
    font-size: 1rem;
    margin-top: 0;
}

.BulletPoint {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.BulletPoint .bullet {
    font-size: .5rem;
    margin: 6px 8px 0 0;
}

.BulletPoint .text {
    font-size: 1rem;
}

@media only screen and (max-width: 750px) {
    .Experience {
        margin: 0 16px;
    }
    .ExperienceItem {
        flex-direction: column;
        width: 100%;
    }
    .ExperienceItem .left-column {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
    }
    .ExperienceItem .right-column {
        margin: 16px 0 0 0;
        width: 100%;
    }
    .DateRange {
        margin: 0;
    }
    .ExperienceItem .left-column .Button {
        margin: 0 0 8px;
    }
}

@media only screen and (max-width: 500px) {
    .Experience {
        margin: 0;
    }
    .ExperienceItem .left-column .date-ranges {
        margin: 16px 0 0 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
    }
    .ExperienceItem .left-column .buttons {
        margin: 16px 0 0 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    }
    .ExperienceItem .left-column .buttons .Button {
        margin: 0 16px 0 0;
    }
}

.Header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    color: var(--color-light);
}

.Header img {
    height: 168px;
    margin: 16px;
}

.Header h1 {
    max-height: 10vh;
    margin: 0;
}

.Header .menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.Header .menu .menu-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 4px;
    margin: 0 24px 0 0;
    text-decoration: none;
    color: var(--color-light);
    cursor: pointer;
}
.Header .menu .menu-item:last-child {
    margin: 0;
}

.Header .menu .menu-item.selected {
    padding: 12px 4px 8px;
    border-bottom: #9fa8da 4px solid;
}

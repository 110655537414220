.Skills {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 750px;
    color: var(--color-light);
}

.Skills .header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    font-style: italic;
    border: 2px solid var(--color-dark);
    width: 100%;
    margin: 0 0 8px;
}

.Skills .header .item {
    text-decoration: underline;
}

.Skills .item {
    width: 300px;
    text-align: left;
}

.Skills .table .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.Skills .table .row .title {
    font-weight: bold;
    text-align: left;
    width: 200px;
}

@media only screen and (max-width: 750px) {
    .Skills .item {
        width: 33%;
    }
    .Skills .table .row .title {
        width: 33%;
    }
}

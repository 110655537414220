.Toast {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.Toast .message {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .2);
    color: white;
    min-width: 400px;
    padding: 16px;
    box-shadow: 0 0 6px #222222;
    margin: 0 0 16px 0;
}

.Toast .message.visible {
    opacity: 1;
    transition: opacity 0.5s;
}

.Toast .message.invisible {
    opacity: 0;
    transition: opacity 0.5s;
}
